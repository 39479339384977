<template>
  <b-container class="mt-3">
    <h2>Create Link</h2>
    <hr class="mt-1" />

    <b-form @submit.prevent="createLink">
      <b-form-group
        id="linkGroup"
        label="Link"
        label-for="linkInput"
      >
        <b-form-input
          id="linkInput"
          v-model="form.link"
          required
          placeholder="Enter link url"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="linkTitleGroup"
        label="Link name"
        label-for="lastNameInput"
      >
        <b-form-input
          id="linkTitleInput"
          v-model="form.link_title"
          required
          placeholder="Enter link name"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" block :disabled="creating"
        >Create Link <b-spinner small v-if="creating"
      /></b-button>
    </b-form>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      creating: false,
      form: {
        link: "",
        link_title: "",
      },
    };
  },
  methods: {
    async createLink() {
      this.creating = true;

      try {
        // create link
        await this.$http.post(
          this.$store.state.apiURL + "/links",
          this.form,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.$router.push({ name: "manageLinks" });
      } catch (error) {
        this.handleError(error);
      }
      this.creating = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss"; 
</style>
